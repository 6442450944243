import { ReactElement } from 'react';

import { categoriesActionTypes, headerLinksActionTypes } from '@actionTypes';
import { FullWidthPageLayout } from '@components';
import { anonymousAxios } from '@helpers';
import { HomeStructureModel, NextPageWithLayout } from '@models';
import { wrapper } from '@store';
import type { NextPage } from 'next';

import HomePage from './home';

interface Props {
  homeData: HomeStructureModel;
}

const Home: NextPage<Props> = (props) => {
  return <HomePage homeData={props.homeData} />;
};

(Home as NextPageWithLayout).getLayout = function getLayout(
  page: ReactElement
) {
  return <FullWidthPageLayout>{page}</FullWidthPageLayout>;
};

export const getStaticProps = wrapper.getStaticProps(
  (store) =>
    async ({ preview, locale }) => {
      const homeDataReq = await anonymousAxios.get('v2/cms/home');

      if (!homeDataReq.data) {
        throw new Error(
          `Failed to fetch home-cms, received status ${homeDataReq.status}`
        );
      }

      const categoriesReq = await anonymousAxios.get('catalog/categories');

      if (!categoriesReq.data) {
        throw new Error(
          `Failed to fetch categories, received status ${categoriesReq.status}`
        );
      }

      const headerLinksReq = await anonymousAxios.get('cms/headers_link');

      if (!headerLinksReq.data) {
        throw new Error(
          `Failed to fetch headerLinks, received status ${headerLinksReq.status}`
        );
      }

      store.dispatch({
        type: headerLinksActionTypes.LOAD_HEADER_LINKS_SUCCESS,
        data: headerLinksReq.data,
      });

      store.dispatch({
        type: categoriesActionTypes.LOAD_ALL_CATEGORIES_SUCCESS,
        data: categoriesReq.data,
      });

      return {
        props: {
          homeData: homeDataReq.data,
        },
        revalidate: +(
          process.env.NEXT_PUBLIC_CACHE_REVALIDATION_IN_SECONDS ?? 60
        ),
      };
    }
);

export default Home;
